import type { UserSearch, UserSearch_profiles } from 'operations/UserSearch'

import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'

import useFilters from 'components/hooks/useFilters'
import { Avatar } from 'components/ui'

import CurrentJob from './common/current_job'
import SearchIcon from './common/search_icon'
import useKeyPress from './hooks/useKeyPress'
import useOutsideClick from './hooks/useOutsideClick'
import SearchRecents from './search_recents'

interface ISearchResult {
  isUserTyping: boolean
  searchResult: UserSearch
  searchParam: string
  onSelectSearch: ({ profile }: { profile: UserSearch_profiles }) => void
  closePopup: () => void
  isBlurred: boolean
  onClickOutside: (isClickedOutside: boolean) => void
}

const SearchResult: FunctionComponent<ISearchResult> = ({
  isUserTyping,
  searchResult,
  searchParam,
  onSelectSearch,
  closePopup,
  isBlurred,
  onClickOutside,
}) => {
  const { addFilter } = useFilters()
  const wrapperRef = useRef<HTMLUListElement>(null)
  const [isClickedOutside, setIsClickedOutside] = useState(false)
  useOutsideClick({ ref: wrapperRef, setState: setIsClickedOutside })
  const router = useRouter()

  const initialState = { selectedIndex: 0 }

  const reducer = (
    state: { selectedIndex: number },
    action: { type: string },
  ) => {
    switch (action.type) {
      case 'arrowUp':
        return {
          selectedIndex:
            state.selectedIndex !== 0
              ? state.selectedIndex - 1
              : searchResult?.profiles?.length - 1,
        }
      case 'arrowDown':
        return {
          selectedIndex:
            state.selectedIndex !== searchResult?.profiles?.length - 1
              ? state.selectedIndex + 1
              : 0,
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const onClick = (result: UserSearch_profiles) => {
    onSelectSearch({ profile: result })
    closePopup()
  }

  const onSelected = useCallback(
    (result: UserSearch_profiles) => {
      if (searchParam === '') return
      if (!result) return
      onSelectSearch({ profile: result })
      closePopup()
      router.push(`/be/${result.username}`)
    },
    [closePopup, onSelectSearch, router, searchParam],
  )

  const seeAllResults = () => {
    closePopup()
    addFilter({
      field: 'search',
      inputValue: searchParam,
    })
  }

  useEffect(() => {
    onClickOutside(isClickedOutside)
  }, [isBlurred, isClickedOutside, onClickOutside])

  const arrowUpPressed = useKeyPress('ArrowUp')
  const arrowDownPressed = useKeyPress('ArrowDown')
  const enterPressed = useKeyPress('Enter')

  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: 'arrowUp' })
    }
    if (arrowDownPressed) {
      dispatch({ type: 'arrowDown' })
    }
    if (enterPressed) {
      onSelected(searchResult?.profiles[state.selectedIndex])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowUpPressed, arrowDownPressed, enterPressed])

  return (
    <>
      {isUserTyping && (
        <div className="lightbox">
          <div className="relative w-full h-full">
            <div className="fixed left-3.5 w-11/12 bg-white rounded-b-xl shadow-md md:left-48 md:w-1/2 top-[70px]">
              <div className="grid grid-cols-1 h-auto">
                <ul
                  tabIndex={0}
                  ref={wrapperRef}
                  role="listbox"
                  className="my-2"
                >
                  {searchResult?.profiles?.map((profile, i) => {
                    const {
                      experiences,
                      first_name,
                      last_name,
                      id,
                      current_job_new,
                      username,
                    } = profile

                    return (
                      <li key={id} role="option" aria-selected={false}>
                        <Link href={`/be/${username}`} passHref legacyBehavior>
                          <button
                            onClick={() => onClick(profile)}
                            className={clsx(
                              'p-2 flex w-full justify-between items-center capitalize hover:bg-gray-light',
                              i === state.selectedIndex && 'bg-gray-light',
                              i == 0 ? 'font-semibold' : 'font-regular',
                            )}
                          >
                            <span className="flex items-center w-10/12">
                              <SearchIcon className="flex-none" />
                              <span className="text-sm min-w-fit">
                                {first_name} {last_name}
                              </span>
                              <div className="overflow-hidden capitalize max-w-fit">
                                <CurrentJob
                                  className="ml-3 min-w-0 text-xs font-normal text-left text-gray-mid truncate"
                                  currentJob={current_job_new}
                                  experiences={experiences}
                                />
                              </div>
                            </span>
                            <div>
                              <Avatar
                                objectFit="cover"
                                layout="fill"
                                className="inline mr-2 rounded-full"
                                profile={profile}
                                size={8}
                                alt="Profile image"
                              />
                            </div>
                          </button>
                        </Link>
                      </li>
                    )
                  })}
                  <li role="option" aria-selected={false}>
                    <button
                      onClick={seeAllResults}
                      className="py-2 px-5 w-full font-bold truncate text-primary hover:bg-gray-light"
                    >
                      See all results
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isUserTyping && (
        <SearchRecents
          closePopup={closePopup}
          isBlurred={isBlurred}
          onClickOutside={onClickOutside}
        />
      )}
    </>
  )
}

export default SearchResult

import type {
  UserSearch_profiles,
  UserSearch_profiles_experiences_search,
} from 'operations/UserSearch'

import Link from 'next/link'
import { FunctionComponent } from 'react'

import { Avatar } from 'components/ui'

import CurrentJob from './common/current_job'

interface RecentSearchProfileProps {
  profiles: Array<
    UserSearch_profiles & { recentJob?: UserSearch_profiles_experiences_search }
  >
  children?: React.ReactNode
  closePopup?: () => void
}

const RecentSearchedProfiles: FunctionComponent<RecentSearchProfileProps> = ({
  profiles,
  closePopup,
  children,
}) => {
  return (
    <>
      {profiles?.slice(0, 3).map((profile, i) => {
        const { first_name, last_name } = profile

        return (
          <div
            key={i}
            className="block justify-center p-2 cursor-pointer hover:bg-gray-light"
          >
            <Link href={`/be/${profile.username}`} passHref legacyBehavior>
              <button
                onClick={closePopup}
                className="font-bold capitalize text-primary"
              >
                <div>
                  <Avatar
                    objectFit="cover"
                    layout="fill"
                    className="object-cover rounded-full"
                    profile={profile}
                    size={10}
                  />
                </div>
                <div className="mt-1 text-xs text-center">
                  {first_name} {last_name}
                </div>
              </button>
            </Link>
            {children}
          </div>
        )
      })}
    </>
  )
}

export default RecentSearchedProfiles

import type { UserSearch_profiles } from 'operations/UserSearch'

import { FunctionComponent, useEffect, useRef, useState } from 'react'

import useOutsideClick from './hooks/useOutsideClick'
import RecentSearchedProfiles from './recent_searched_profiles'
import { RECENT_SEARCHES_KEY } from './search_engine'

interface SearchRecentsProps {
  closePopup: () => void
  isBlurred: boolean
  onClickOutside: (isClickedOutside: boolean) => void
}

const SearchRecents: FunctionComponent<SearchRecentsProps> = ({
  closePopup,
  isBlurred,
  onClickOutside,
}) => {
  const [recentSearched, setRecentSearched] = useState<UserSearch_profiles[]>(
    [],
  )
  const [isClickedOutside, setIsClickedOutside] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  useOutsideClick({ ref: wrapperRef, setState: setIsClickedOutside })

  const getRecentSearches = () => {
    let result = localStorage.getItem(RECENT_SEARCHES_KEY)
    setRecentSearched(JSON.parse(result || '[]'))
  }

  useEffect(() => {
    getRecentSearches()
  }, [])

  useEffect(() => {
    onClickOutside(isClickedOutside)
  }, [isBlurred, isClickedOutside, onClickOutside])

  return (
    <div className="lightbox">
      <div className="relative w-full h-full">
        <div className="fixed left-3.5 w-11/12 bg-white rounded-b-xl md:left-48 md:w-1/3 top-[70px]">
          <div className="grid grid-cols-3 p-5 h-32" ref={wrapperRef}>
            <RecentSearchedProfiles
              profiles={recentSearched}
              closePopup={closePopup}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchRecents

import { UserSearch, UserSearch_profiles } from 'operations/UserSearch'

import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect } from 'react'

import SearchIcon from 'components/common/search_icon'
import useFilters from 'components/hooks/useFilters'

import CurrentJob from './common/current_job'
import SearchResults from './search_results'
import { Avatar, Combobox } from './ui'
import { ComboboxOption } from './ui/combobox'

export const RECENT_SEARCHES_KEY = 'recentSearches'
export const Q_GET_SEARCH = gql`
  query UserSearch(
    $condition: profiles_bool_exp
    $limit: Int = 10
    $order_by: [profiles_order_by!] = { first_name: asc }
  ) {
    totalProfiles: profiles_aggregate(where: $condition) {
      aggregate {
        count(columns: id)
      }
    }
    profiles(where: $condition, limit: $limit, order_by: $order_by) {
      first_name
      id
      last_name
      username
      degree
      experiences
      educations
      office_info
      avatar
      owner {
        id
        roles
      }
      current_job_new
      experiences_search(order_by: { end_year: desc, end_month: desc }) {
        title
        organization
      }
    }
  }
`

interface SearchProps {}

const SearchEngine: FunctionComponent<SearchProps> = () => {
  const [searchWord, setSearchWord] = React.useState('')
  const [showResults, setShowResults] = React.useState(false)
  const [isUserTyping, setIsUserTyping] = React.useState(false)
  const [isBlurred, setIsBlurred] = React.useState(false)

  const [selectedOption, setSelectedOption] = React.useState<ComboboxOption>()
  const { filters } = useFilters()

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      setSelectedOption(undefined)

      setSearchWord('')
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  const onClick = () => setShowResults(true)

  const searchQueryCondition = (param: string) =>
    param.indexOf(' ') < 0
      ? {
          condition: {
            _or: [
              { first_name: { _ilike: `${param}%` } },
              { last_name: { _ilike: `${param}%` } },
            ],
          },
        }
      : {
          condition: {
            _and: [
              {
                first_name: {
                  _ilike: `${param.substring(0, param.indexOf(' '))}%`,
                },
              },
              {
                last_name: {
                  _ilike: `${param.substring(param.indexOf(' ') + 1)}%`,
                },
              },
            ],
          },
        }

  const { data, refetch } = useQuery<UserSearch>(Q_GET_SEARCH, {
    variables: searchQueryCondition(searchWord),
  })

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setShowResults(true)
    setIsUserTyping(target.value !== '')
    setSearchWord(target.value)
    refetch(searchQueryCondition(target.value))
  }

  const onClickOutside = (isClickedOutside: boolean) => {
    if (isBlurred && isClickedOutside) {
      setIsBlurred(false)
      setShowResults(false)
    }
  }

  const onSelectSearch = ({ profile }: { profile: UserSearch_profiles }) => {
    const { last_name, first_name, id, avatar, experiences_search, username } =
      profile

    const recentJob = experiences_search?.[0]

    let result = localStorage.getItem(RECENT_SEARCHES_KEY)

    if (!result) {
      localStorage.setItem(
        RECENT_SEARCHES_KEY,
        JSON.stringify([
          { last_name, first_name, id, avatar, recentJob, username },
        ]),
      )
      return
    }

    const recentSearches = JSON.parse(result)
    let hasThisSearch = Boolean(
      recentSearches.find((item: UserSearch_profiles) => item.id == id),
    )

    if (hasThisSearch) {
      return
    }

    const MAX_OF_RECENTS = 8
    if (recentSearches.length == MAX_OF_RECENTS) {
      recentSearches.pop()
      recentSearches.unshift({
        last_name,
        first_name,
        id,
        avatar,
        recentJob,
        username,
      })
    } else {
      recentSearches.unshift({
        last_name,
        first_name,
        id,
        avatar,
        recentJob,
        username,
      })
    }

    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches))
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="grid gap-4 md:w-[270px]">
        <Combobox
          selectedItem={selectedOption}
          optionsWidth="w-[500px]"
          className="flex py-2 -mb-2 self-baseline"
          background="white"
          placeholder="Search Belongly"
          autoComplete="off"
          icon={<SearchIcon />}
          options={data?.profiles.map((profile) => ({
            title: `${profile.first_name} ${profile.last_name}`,
            value: {
              id: profile.id,
              username: profile.username,
              currentJob: profile.current_job_new,
              experiences: profile.experiences,
              expirences_search: profile.experiences_search,
              avatar: profile.avatar,
            },
          }))}
          onSelectItem={(selected) => {
            if (!Array.isArray(selected)) {
              const singleOption = selected as ComboboxOption
              onSelectSearch({ profile: singleOption.value })
              setSelectedOption(selected)
              router.push(`/be/${singleOption.value.username}`)
            }
          }}
          onChange={(e) => {
            refetch(searchQueryCondition(e.target.value))
          }}
          renderOption={(option) => (
            <div
              key={option.value.id}
              className="flex gap-2 justify-between items-center"
            >
              <div className="flex gap-2 items-center">
                <SearchIcon className="flex-none w-4 h-4" />
                <div className="flex gap-2 items-center">
                  <span className="flex-none">{option?.title}</span>
                  <CurrentJob
                    currentJob={option?.value?.currentJob}
                    experiences={option?.value?.experiences}
                    className="text-xs line-clamp-1"
                  />
                </div>
              </div>
              <div>
                <Avatar
                  objectFit="cover"
                  layout="fill"
                  className="inline mr-2 rounded-full"
                  profile={option?.value?.avatar}
                  alt="Profile image"
                  size={8}
                />
              </div>
            </div>
          )}
        />
        {/**
          <input
            className="py-1 px-3 w-full leading-tight rounded-lg border focus:outline-none text-gray bg-gray-light focus:shadow-outline"
            id="search"
            type="text"
            onFocus={onClick}
            onBlur={() => setIsBlurred(true)}
            onChange={onChange}
            value={searchWord || filters?.['search'] || ''}
            placeholder="Search Belongly"
            autoComplete="off"
          />
           * 
           */}
        {showResults && (
          <SearchResults
            searchResult={data!}
            searchParam={searchWord}
            isUserTyping={isUserTyping}
            onSelectSearch={onSelectSearch}
            closePopup={() => setShowResults(false)}
            isBlurred={isBlurred}
            onClickOutside={onClickOutside}
          />
        )}
      </div>
    </div>
  )
}

export default SearchEngine
